<template>
    <div class="Profile-schedule" v-loading.fullscreen="loadingService">
        <div class="schedule-calendar">
            <ul class="week-buttons">
                <li>
                    <button @click="goToPrevWeek"><i class="el-icon-caret-left"></i> Предыдущая неделя</button>
                </li>
                <div v-for="day in weekdays">
                    <li>
                        <button :class="{'active': selectedDate === day[1]}" @click="getData(day[1])">{{ day[0] }}</button>
                    </li>
                </div>
                <li>
                    <button @click="goToNextWeek">Следующая неделя <i class="el-icon-caret-right"></i></button>
                </li>
            </ul>
            <div class="today-date" v-if="selectedDate">
                <span v-if="today === selectedDate">{{ $t("profile.schedule.today") }}</span> {{ moment(selectedDate).locale(momentLocale).format('D MMMM') }}
            </div>
            <table class="table bg-white" v-if="viewMode === 'desktop'">
                <thead>
                <tr>
                    <td>№</td>
                    <td>Предмет и класс</td>
                    <td>Время урока</td>
                    <td>Тема</td>
                    <td>Урок</td>
                    <td>Домашнее задание</td>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(event, key) in events" v-if="events.length">
                    <td>{{ key+1 }}</td>
                    <td>{{ event.subject.name }}
                        <br>
                        <span class="text-muted"><i class="el-icon-user-solid"></i> {{ event.teacher_name }}</span></td>
                    <td>{{ getTime(event.start) }} - {{ getTime(event.end) }}</td>
                    <td>{{ event.title }}</td>
                    <td><el-button class="btn btn-primary" @click="goToLesson(event)">Перейти</el-button></td>
                    <td>
                        <el-button v-if="event.homework" class="btn btn-primary" @click="goToHomework(event.homework)">Перейти</el-button>
                        <el-button v-else disabled class="btn btn-secondary disabled" @click="goToHomework(event.homework)">Не создано</el-button>
                    </td>
                </tr>
                <tr v-if="!events.length">
                    <td colspan="6" class="text-center">Нет данных</td>
                </tr>
                </tbody>
            </table>
            <div class="events" v-if="viewMode === 'mobile'">
                <div class="mb-4" v-for="(event, key) in events" v-if="events.length">
                    <table class="table bg-white mobile-table">
                        <tr>
                            <td>
                                {{ event.subject.name }}<br>
                                <span class="text-muted"><i class="el-icon-user-solid"></i> {{ event.teacher_name }}</span>
                            </td>
                            <td class="text-center">{{ getTime(event.start) }} - {{ getTime(event.end) }}</td>
                        </tr>
                        <tr>
                            <td colspan="2">{{ event.title }}</td>
                        </tr>
                    </table>
                    <div class="container">
                        <div class="row mx-0">
                            <div class="col-6 mb-3">
                                <span class="text-muted pb-4">Урок</span>
                                <el-button class="btn btn-primary btn-block" @click="goToLesson(event)">Перейти</el-button>
                            </div>
                            <div class="col-6">
                                <span class="text-muted pb-4">Домашнее задание</span>
                                <el-button v-if="event.homework" class="btn btn-primary btn-block" @click="goToHomework(event.homework)">Перейти</el-button>
                                <el-button v-else disabled class="btn btn-secondary btn-block disabled" @click="goToHomework(event.homework)">Не создано</el-button>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="!events.length">
                    <div class="text-center">Нет данных</div>
                </div>
            </div>
        </div>

    </div>
</template>
<script>
import moment from 'moment';
export default {
    name: '',
    data(){
        return {
            viewMode: 'desktop',
            selectedDate: '',
            today: moment().format('YYYY-MM-DD'),
            loadingTimeout: false,
            loadingService: false,
            showMoreLessons: false,
            loading: false,
            events: [],
            select_student: '',
            select_school: '',
            select_class: '',
            select_test: '',
            full_name: '',
            weekdays: [],
            momentLocale: this.$i18n.locale === 'kz' ? 'kk' : 'ru',
            attrs: [
                {
                    key: 'today',
                    highlight: true,
                    dates: new Date(),
                },
            ],
            currentWeek: moment().format('w'),
            currentDay: moment().format("D"),
            currentMonth: moment().locale('ru').format("MMMM"),
            currentYear: moment().format("YYYY"),
            currentWeekDay: moment().locale('ru').format('dddd')
        }
    },
    mounted() {
        if (window.innerWidth < 600) {
            this.viewMode = 'mobile'
        }
        this.getWeekdays()
        this.getData(this.today)
    },
    methods: {
        getData(date) {
            this.loadingService = true;
            this.loadingTimeout = setTimeout(() => {
                this.loadingService = false;
            }, 5000);
            this.loading = true;
            this.$http.get(`${window.API_ROOT}/api/schedule/day?date=${date}`)
                .then((res) => {
                    this.selectedDate = date
                    this.loading = false
                    this.events = res.body
                    this.loadingService = false
                    window.clearTimeout(this.loadingTimeout)
                }).catch(() => {
                    this.loadingService = false
                    this.loading = false
                    window.clearTimeout(this.loadingTimeout)
                })
        },
        getTime(date){
            return moment(date).format('HH:mm')
        },
        getWeekdays(){
            this.weekdays = Array.apply(null, Array(6)).map((_, i) => {
                let res = []
                let momentDate = moment(this.currentWeek, 'w').startOf('isoWeek').isoWeekday(i + 8);
                res.push(momentDate.locale(this.momentLocale).format('dddd'), momentDate.format('YYYY-MM-DD'))
                return res;
            });
        },
        goToPrevWeek() {
            this.currentWeek--
            this.selectedDate = moment(this.selectedDate).subtract(1, 'weeks').format(('YYYY-MM-DD'))
            this.getData(this.selectedDate)
            this.weekdays = Array.apply(null, Array(6)).map((_, i) => {
                let res = []
                let momentDate = moment(this.currentWeek, 'w').startOf('isoWeek').isoWeekday(i + 8);
                res.push(momentDate.locale(this.momentLocale).format('dddd'), momentDate.format('YYYY-MM-DD'))
                return res;
            })
        },
        goToNextWeek() {
            this.currentWeek++
            this.selectedDate = moment(this.selectedDate).add(1, 'weeks').format(('YYYY-MM-DD'))
            this.getData(this.selectedDate)
            this.weekdays = Array.apply(null, Array(6)).map((_, i) => {
                let res = []
                let momentDate = moment(this.currentWeek, 'w').startOf('isoWeek').isoWeekday(i + 8);
                res.push(momentDate.locale(this.momentLocale).format('dddd'), momentDate.format('YYYY-MM-DD'))
                return res;
            })
        },
        goToLesson(event) {
            this.$router.push({ name: 'lesson-detail', params: { id: event.id } })
        },
        goToHomework(event){
            this.$router.push({ name: 'homework-detail', params: { id: event.schedule_id } })
        }
    }
}
</script>
<style>
.week-buttons{
    background: #473f95;
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
    border-radius: 10px 10px 0 0;
    overflow: hidden;
}
.week-buttons li {
    float: left;
}
.week-buttons li button{
    background: #473f95;
    color: #fff;
    text-transform: uppercase;
    border: none;
    font-size: 14px;
    padding: 14px 16px;
}
.week-buttons li button.active{
    background: #fff;
    color: #473f95;
    border-radius: 6px 6px 0 0;
}
.today-date {
    text-transform: uppercase;
    background: #fff;
    font-size: 18px;
    padding: 10px 14px;
    color: #666;
}
.mobile-table td{
    vertical-align: middle;
}
.btn.btn-primary,
.btn.btn-secondary{
    border: none;
}
@media (max-width: 575px) {
    .week-buttons li{
        width: 100%;
    }
    .week-buttons li button{
        width: 100%;
        text-align: left;
    }
    .week-buttons li button.active{
        border-radius: 0;
    }
    .today-date{
        text-align: center;
    }
}
@media (max-width: 420px) {
    .events .col-6{
        width: 100%;
        max-width: 100%;
        flex: inherit;
    }
}

</style>
